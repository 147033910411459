exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-angular-ca-js": () => import("./../../../src/pages/Angular.ca.js" /* webpackChunkName: "component---src-pages-angular-ca-js" */),
  "component---src-pages-elements-ca-js": () => import("./../../../src/pages/Elements.ca.js" /* webpackChunkName: "component---src-pages-elements-ca-js" */),
  "component---src-pages-generic-ca-js": () => import("./../../../src/pages/Generic.ca.js" /* webpackChunkName: "component---src-pages-generic-ca-js" */),
  "component---src-pages-index-ca-js": () => import("./../../../src/pages/index.ca.js" /* webpackChunkName: "component---src-pages-index-ca-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-kit-digital-ca-js": () => import("./../../../src/pages/kit-digital.ca.js" /* webpackChunkName: "component---src-pages-kit-digital-ca-js" */),
  "component---src-pages-kit-digital-en-js": () => import("./../../../src/pages/kit-digital.en.js" /* webpackChunkName: "component---src-pages-kit-digital-en-js" */),
  "component---src-pages-kit-digital-es-js": () => import("./../../../src/pages/kit-digital.es.js" /* webpackChunkName: "component---src-pages-kit-digital-es-js" */)
}

